import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { TopBar } from '../../components';

import './TermsAndPrivacyPage.css';

class TermsAndPrivacyPage extends Component {

    state = {
        divClass: 'termsAndPrivacyContent',
    };

    componentDidMount(){
        this.fadeIn();
    }

    fadeIn = () => {
        setTimeout(() => {
            this.setState({
                divClass: 'termsAndPrivacyContent active'
            })
        }, 500);
    };

    render() {
        const { history } = this.props;
        return (
            <div className={this.state.divClass}>
                <MetaTags>
                    <title>Entertainment Intelligence | About Page</title>
                    <meta name="description" content="Entertainment Intelligence" />
                    <meta property="og:title" content="Entertainment Intelligence" />
                    <meta property="og:image" content="./og-ei.jpg" />
                </MetaTags>
                <TopBar history={history} color={'black'} />
                <div className="privacyTextHolder">
                
                <h2>PRIVACY POLICY</h2>
                
                <p className="privacyText">
                This Privacy Policy explains what information we collect from you when you access our services and how that information is used.
                </p>
                
                <p className="privacyText">
                1. Definitions. As used in this Privacy Policy and Terms of Service,
                    <ul className="abc">
                        <li>"Personal Data" means any information relating a natural person that may identify that person directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, or other relevant information. "Personal Data" does not apply to business entities, but does apply to individual "Stakeholders" (as defined below).</li>
                        <li>"Client" means a business entity that has entered into a Services Agreement with Entertainment Intelligence ("Ei"), pursuant to which Ei provides the Client and its designated Stakeholders access to Ei's Dashboard and services;</li>
                        <li>"Stakeholder(s)" means the Client's employees and independent contractors, any label distributed by the Client, artists whose recordings are released on such labels, managers of such artists, and others who may have a financial or ownership interest in master recordings and musical compositions released on such labels.</li>
                    </ul>
                </p>
                
                
                <p className="privacyText">
                2. Information that we collect. We collect the following information:
                    <ul>
                    <li>Your first and last name, e-mail address, and password, each necessary for logging in to our Dashboard ;</li>
                    <li>Your IP address when you log in;</li>
                    <li>We may also ask for your location information (city, country and time zone), although we are not currently requesting that information);</li>
                    <li>Communications between you and us, e.g., support tickets.</li>
                    </ul>
                </p>
                <p className="privacyText">
                3. Information that we do NOT collect.
                <ul>
                <li>We do not collect analytics information or install device identifiers on your devices.</li>
                <li>We do not collect any metadata from you.</li>
                </ul>
                </p>
                <p className="privacyText">
                4. How we use your Personal Data.
                <ul>
                    <li>We use the Personal Data that we receive from you to allow you to log in to our Dashboard and use our Services.</li>
                    <li> If we sell or otherwise transfer our Services or assets to another company (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, liquidation), your name, email address, and IP log-in address(es) may be among the items sold or transferred, and the buyer or transferee will have to honor the commitments we have made in this Privacy Policy.</li>
                    <li> We do not share your Personal Data with anyone. However, if you are a Stakeholder, we may verify with the Client that you are an authorized user under the Client's Services Agreement with us.</li>
                    <li> We may access, preserve and share your Personal Data in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so.</li>
                </ul>
                </p>
                <p className="privacyText">
                5. How we protect your Personal Data.
                    <ul>
                        <li> We implement physical, business and technical security measures to protect all such information. Your password is encrypted.</li>
                    </ul>
                </p>
                <p className="privacyText">
                6. How your Personal Data may be deleted.
                <ul>
                    <li> If our Client terminates its relationship with us, the Client's information, including any Personal Data of Stakeholders is deleted, following which none of the foregoing will have access to our Dashboard or our services.</li>
                    <li> You may send us an email at info@entertainment-intelligence.com asking us to remove your Personal Data from our servers, following which you will no longer have access to our Dashboard or our services.</li>
                </ul>
                </p>
                <p className="privacyText">
                7. How we store your Personal Data.
                <ul>
                    <li> The personal data that we collect is stored in the United Kingdom and elsewhere where our servers may be backed up.</li>
                    <li> We use commercially reasonable safeguards to help keep personal data secure. However, you are also responsible at all times for controlling access to emails between you and us, and ensuring that your password remains secure. We are not responsible for the functionality, privacy, or security measures of any other person or organization.</li>
                </ul>
                </p>
                <p className="privacyText">
                8. How long we keep your Personal Data.
                <ul>
                  <li> We keep your Personal Data until such time as it is deleted as described in Section 6 above.</li>                
                  </ul>
              </p>
                </div>
                

            </div>
        );
    }
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedTermsAndPrivacyPage = connect(mapStateToProps)(TermsAndPrivacyPage);
export {connectedTermsAndPrivacyPage as TermsAndPrivacyPage};
