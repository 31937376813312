import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../../_helpers';
import { HomePage, RegisterPage, ServicesPage, ClientsPage, AboutPage, NoMatchPage, TermsAndPrivacyPage } from '../../containers';

import '../../styles/index.css';
import './App.css';


class App extends Component {

    renderVideo(){
        if (window.matchMedia("(min-width: 2880px)").matches) {
            return(
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./2880x1800.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./2880x1800.mp4"></source>
                </video>
            )
        }
        else if (window.matchMedia("(min-width: 1920px)").matches) {
            return(
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./1920x1080.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./1920x1080.mp4"></source>
                </video>
            )
        }
        else if (window.matchMedia("(min-width: 1280px) and (min-height: 1024px)").matches) {
            return(
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./2000x1800.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./2000x1800.mp4"></source>
                </video>
            )
        }
        else if (window.matchMedia("(min-width: 1280px)").matches) {
            return(
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./1920x1080.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./1920x1080.mp4"></source>
                </video>
            )
        }

        else if (window.matchMedia("(min-width: 768px)").matches) {
            return (
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./768x1024.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./768x1024.mp4"></source>
                </video>
            )
        }
        else {
            return(
                <video autoPlay={true}
                       className="bgVideo"
                       loop
                       muted
                       preload="auto"
                       src="./450x850.mp4">

                    <source type="video/mp4" data-reactid=".0.1.0.0.0" src="./450x850.mp4"></source>
                </video>
            )
        }
    }


    render() {
        return (
            <div className={`appContent`}>


                <div className="bgVideoHolder">
                    {this.renderVideo()}
                </div>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/register" component={RegisterPage} />
                        <Route path="/services" component={ServicesPage} />
                        <Route path="/clients" component={ClientsPage} />
                        <Route path="/about" component={AboutPage} />
                        <Route path="/terms-and-privacy" component={TermsAndPrivacyPage} />
                        <Route component={NoMatchPage} />
                    </Switch>

                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
